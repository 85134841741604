<div
	class="button"
	(click)="prev()"
	[class.disabled]="disabledPrev"
>
	<mat-icon
		svgIcon="chevron-right"
		class="rotate180"
	></mat-icon>
</div>

<div
	class="title"
	(click)="openSelectMonth.emit()"
>
  <ng-container #viewContainer></ng-container>
</div>

<div
	class="button"
	(click)="next()"
	[class.disabled]="disabledNext"
>
	<mat-icon svgIcon="chevron-right"></mat-icon>
</div>

<ng-template
	#templateContainer
	let-index=index
>
  <div>
    {{ nameMonth[index] }}
  </div>
</ng-template>
