import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICalendarModes, ViewModeCalendar, ViewModeDate} from '@shared/ui-components/n-calendar/models/calendar.types';
import {CalendarDataService} from '@shared/ui-components/n-calendar/services/calendar-data.service';

@Component({
	selector: 'app-calendar-header',
	templateUrl: 'calendar-header.component.html',
	styleUrls: ['calendar-header.component.scss'],
})
export class CalendarHeaderComponent {
	@Input() viewModeDate: ViewModeDate = 'months';
	@Input() viewModeCalendar: ViewModeCalendar = 'calendar';
	@Input() viewDateTime: number;

	@Output() changeMode: EventEmitter<ICalendarModes> =
		new EventEmitter<ICalendarModes>();

	constructor(private _calendarDataService: CalendarDataService) {
	}

	public setMonth(date: Date): void {
		this._calendarDataService.setViewTime(date);
	}

	public onChangeMode(dateMode: ViewModeDate, calendarMode: ViewModeCalendar): void {
		this._calendarDataService.setViewModes({
			dateMode: dateMode,
			calendarMode: calendarMode,
		});
	}

	public close(): void {
		this._calendarDataService.setMonthsYear(null);
		this.onChangeMode('months', 'calendar');
	}
}
