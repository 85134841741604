import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ICalendarModes, IPeriodYears} from '@shared/ui-components/n-calendar/models/calendar.types';


@Injectable()
export class CalendarDataService {
	private _viewModes: BehaviorSubject<ICalendarModes>;
	private _currentTime: BehaviorSubject<Date>;
	private _periodYears: Subject<IPeriodYears>;
	private _monthsYear: BehaviorSubject<number>;
	private _selectedPeriodDate: BehaviorSubject<IPeriodYears>;
	private _minDate: Date | null;
	private _maxDate: Date | null;
	private _disableDate: Map<string, boolean>;

	constructor() {
		this._viewModes = new BehaviorSubject<ICalendarModes>(null);
		this._currentTime = new BehaviorSubject<Date>(null);
		this._periodYears = new Subject<IPeriodYears>();
		this._monthsYear = new BehaviorSubject<number>(null);
		this._selectedPeriodDate = new BehaviorSubject<IPeriodYears>(null);
		this._disableDate = new Map();

		this._currentTime.subscribe(() => this._monthsYear.next(null));
	}

	public setMonthsYear(year: number): void {
		this._monthsYear.next(year);
	}

	public getMonthsYear(): number | null {
		return this._monthsYear.getValue();
	}

	public getMonthsYearListeners(): Observable<number> {
		return this._monthsYear
			.asObservable()
			.pipe(filter(event => event !== null));
	}

	public setMinDate(min: Date | null): void {
		if (min === null) {
			this._minDate = null;
			return;
		}

		const minDate = new Date(min);
		minDate.setHours(0, 0, 0, 0);
		this._minDate = minDate;
	}

	public getMinDate(): Date | null {
		return this._minDate;
	}

	public setMaxDate(max: Date): void {
		if (max === null) {
			this._maxDate = null;
			return;
		}
		const maxDate = new Date(max);
		maxDate.setHours(23, 59, 59, 999);
		this._maxDate = maxDate;
	}

	public getMaxDate(): Date | null {
		return this._maxDate;
	}

	public setSelectedPeriod(period: IPeriodYears): void {
		this._selectedPeriodDate.next(period);
	}

	public getSelectedPeriod(): IPeriodYears {
		return this._selectedPeriodDate.getValue();
	}

	public getSelectedPeriodListener(): Observable<IPeriodYears> {
		return this._selectedPeriodDate
			.asObservable()
			.pipe(filter(event => !!event));
	}

	public setPeriodYears(period: IPeriodYears): void {
		this._periodYears.next(period);
	}

	public getPeriodYearsListener(): Observable<IPeriodYears> {
		return this._periodYears.asObservable();
	}

	public setViewModes(viewModes: ICalendarModes): void {
		this._viewModes.next(viewModes);
	}

	public getViewModesListener(): Observable<ICalendarModes> {
		return this._viewModes.asObservable().pipe(filter(event => !!event));
	}

	public setViewTime(date: Date): void {
		this._currentTime.next(date);
	}

	public getViewTimeListener(): Observable<Date> {
		return this._currentTime.asObservable().pipe(filter(event => !!event));
	}

	public getViewTime(): Date {
		return this._currentTime.getValue();
	}

	public getCountDaysMonth(date: Date): number {
		return 33 - new Date(date.getFullYear(), date.getMonth(), 33).getDate();
	}

	public getFirstDayMonth(date: Date): number {
		const dateCopy = new Date(date);
		dateCopy.setDate(1);

		return this.fixWeekDayShift(dateCopy);
	}

	public getLastDayMonth(date: Date): number {
		const dateCopy = new Date(date);
		dateCopy.setDate(this.getCountDaysMonth(date));

		return this.fixWeekDayShift(dateCopy);
	}

	private fixWeekDayShift(date: Date): number {
		const day = date.getDay();

		return day === 0 ? 6 : day - 1;
	}

	public getPreviousViewDays(viewDate: Date): number[] {
		const startDay = this.getFirstDayMonth(viewDate);

		const previousMonth = new Date(viewDate);
		previousMonth.setMonth(previousMonth.getMonth() - 1);

		const countPreviousMonthDays = this.getCountDaysMonth(previousMonth);

		let startDayPrevMonth = countPreviousMonthDays - (startDay - 1);

		const previousViewDays = [];

		for (
			;
			startDayPrevMonth <= countPreviousMonthDays;
			startDayPrevMonth++
		) {
			previousMonth.setDate(startDayPrevMonth);
			this.setModifiers(previousMonth, 'prev');
			previousViewDays.push(startDayPrevMonth);
		}

		return previousViewDays;
	}

	public getNextViewDays(viewDate: Date): number[] {
		const lastDay = this.getLastDayMonth(viewDate);

		let startDayNextMonth = 6 - lastDay;

		const nextViewDays = [];
		const nextMonthDate = new Date(viewDate);
		nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
		for (let i = 1; i <= startDayNextMonth; i++) {
			nextMonthDate.setDate(i);
			this.setModifiers(nextMonthDate, 'next');
			nextViewDays.push(i);
		}

		return nextViewDays;
	}

	public getViewDays(viewDate: Date): number[] {
		const viewDays = [];

		const monthDate = new Date(viewDate);
		for (let i = 1; i <= this.getCountDaysMonth(viewDate); i++) {
			monthDate.setDate(i);
			this.setModifiers(monthDate, 'current');
			viewDays.push(i);
		}

		return viewDays;
	}

	public setModifiers(date: Date, prefix: string): void {
		if (!this.checkOnMinMaxDate(date)) {
			this._disableDate.set(prefix + date.getDate(), true);
		} else {
			this._disableDate.set(prefix + date.getDate(), false);
		}
	}

	public isDateDisabled(key: string): boolean {
		return this._disableDate.get(key);
	}

	public checkOnMinMaxDate(date: Date): boolean {
		return !(
			(this._minDate !== null &&
				this._minDate.getTime() > date.getTime()) ||
			(this._maxDate && this._maxDate.getTime() < date.getTime())
		);
	}

	public getNumberDate(date: Date): number {
		return (
			date.getDate() + date.getMonth() * 100 + date.getFullYear() * 10000
		);
	}
}
