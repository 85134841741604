import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class IntervalUtils {
	public setBeginDay(date: Date): Date {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);

		return date;
	}

	public setEndDay(date: Date): Date {
		date.setHours(23);
		date.setMinutes(59);
		date.setSeconds(59);
		date.setMilliseconds(999);

		return date;
	}
}
