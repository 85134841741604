import {
	Directive,
	DoCheck,
	ElementRef,
	EmbeddedViewRef,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { CalendarComponent } from '@shared/ui-components/n-calendar/calendar.component';
import { DOCUMENT } from '@angular/common';

@Directive({
	selector: '[appCalendar]',
})
export class CalendarDirective implements DoCheck, OnDestroy, OnInit {
	@Input() calendarTemplate: TemplateRef<CalendarComponent>;
	@Input() withoutClick = false;
	@Input() componentPosition: HTMLElement | null = null;

	private _component: EmbeddedViewRef<CalendarComponent> | null;
	private _componentNode: HTMLElement | null;

	constructor(
		private _viewContainerRef: ViewContainerRef,
		private elementRef: ElementRef,
		@Inject(DOCUMENT) private document: Document
	) {
		this._componentNode = null;
		this._component = null;
	}

	public ngOnInit(): void {
		this.componentPosition = !!this.componentPosition
			? this.componentPosition
			: this.elementRef.nativeElement;
	}

	public ngOnDestroy(): void {
		if (this._component) {
			this.destroyPopoverComponent();
		}
	}

	@HostListener('click', ['$event'])
	public onClick(event: Event): void {
		if (!this.withoutClick) {
			event.stopPropagation();

			this.callCalendar();
		}
	}

	@HostListener('document:click', ['$event'])
	public onClickOutside(): void {
		if (this._component) {
			this.destroyPopoverComponent();
		}
	}

	public isOpened(): boolean {
		return !!this._component;
	}

	public callCalendar(): void {
		if (this._component) {
			this.destroyPopoverComponent();
		} else {
			this.createPopoverComponent();
		}
	}

	public createPopoverComponent(): void {
		this._component = this._viewContainerRef.createEmbeddedView(
			this.calendarTemplate
		);
		this._componentNode = this._component.rootNodes[0] as HTMLElement;
		this._componentNode.style.position = 'fixed';
		this._componentNode.style.zIndex = '199995';
		this.document.body.appendChild(this._componentNode);

		this._componentNode.onclick = (event: MouseEvent) => {
			event.stopPropagation();
		};

		this.definitionLocation();
	}

	public destroyPopoverComponent(): void {
		this._component.destroy();
		this._component = null;
		this._componentNode = null;
	}

	public ngDoCheck(): void {
		if (!!this._componentNode) {
			this.definitionLocation();
		}
	}

	private definitionLocation(): void {
		const { bottom, height, left, right, top, width } =
			this.componentPosition.getBoundingClientRect();
		const calendarWidth = this._componentNode.offsetWidth;
		const calendarHeight = this._componentNode.offsetHeight;

		if (this.document.body.offsetWidth > calendarWidth + left) {
			this._componentNode.style.left = left + 'px';
		} else {
			this._componentNode.style.left = right - calendarWidth + 'px';
		}

		if (this.document.body.offsetHeight < calendarHeight + bottom + 10) {
			this._componentNode.style.top = top - 10 - calendarHeight + 'px';
		} else {
			this._componentNode.style.top = bottom + 10 + 'px';
		}
	}
}
