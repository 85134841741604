<app-years-paginator
	*ngIf="viewModeDate === 'years' && viewModeCalendar === 'date'"
></app-years-paginator>

<app-year-paginator
	*ngIf="viewModeDate === 'months' && viewModeCalendar === 'date'"
></app-year-paginator>

<app-months-paginator
	*ngIf="viewModeDate === 'months' && viewModeCalendar === 'calendar'"
	(openSelectMonth)="onChangeMode('months', 'date')"
>
</app-months-paginator>

<mat-icon *ngIf="viewModeCalendar === 'date'"
		  svgIcon="close" class="btn-close"
		  (click)="close()"
></mat-icon>

<div *ngIf="viewModeCalendar === 'calendar'"
	 class="year"
	 (click)="onChangeMode('years', 'date')"
>
	{{ viewDateTime | date: 'yyyy' }}
</div>
