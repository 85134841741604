import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {HEIGHT_MATRIX_YEARS, NAME_MONTH, WIDTH_MATRIX_YEARS,} from '@shared/ui-components/n-calendar/models/calendar.constants';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IPeriodYears, ViewModeDate} from '@shared/ui-components/n-calendar/models/calendar.types';
import {CalendarDataService} from '@shared/ui-components/n-calendar/services/calendar-data.service';

@UntilDestroy()
@Component({
	selector: 'app-select-year',
	templateUrl: 'select-date.component.html',
	styleUrls: ['select-date.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDateComponent implements OnInit {
	@Input() viewMode: ViewModeDate = 'years';

	@Output() changeDate: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	public months: number[];
	public years: number[];

	public selectedPeriod: Map<number, string>;
	public disableDate: Map<number, boolean>;

	private _selectedDate: Date;

	constructor(
		private _calendarDataService: CalendarDataService,
		private _changeDetectorRef: ChangeDetectorRef
	) {
		this.months = [];
		this.years = [];

		this.selectedPeriod = new Map();
		this.disableDate = new Map();
	}

	public ngOnInit(): void {
		this.initListeners();
	}

	private initListeners(): void {
		this._calendarDataService
			.getPeriodYearsListener()
			.pipe(untilDestroyed(this))
			.subscribe((period: IPeriodYears) => {
				this.initYears(period.from + WIDTH_MATRIX_YEARS);

				this._changeDetectorRef.markForCheck();
			});

		this._calendarDataService
			.getViewTimeListener()
			.pipe(untilDestroyed(this))
			.subscribe(res => {
				this._selectedDate = new Date(res);
			});

		this._calendarDataService
			.getMonthsYearListeners()
			.pipe(
				untilDestroyed(this),
				filter(() => this.viewMode === 'months')
			)
			.subscribe(year => {
				this._selectedDate.setFullYear(year);
				this.initMonth(year);

				this._changeDetectorRef.markForCheck();
			});

		this._calendarDataService
			.getViewModesListener()
			.pipe(
				untilDestroyed(this),
				distinctUntilChanged(
					(prev, current) => current.dateMode === prev.dateMode
				)
			)
			.subscribe(modes => {
				if (modes.dateMode === 'months') {
					this.initMonth();
				} else {
					this.initYears(this._selectedDate.getFullYear());
				}
			});
	}

	private initYears(year: number): void {
		this.years = [];
		let yearStart = year - WIDTH_MATRIX_YEARS;
		const yearEnd =
			year + ((HEIGHT_MATRIX_YEARS - 1) * WIDTH_MATRIX_YEARS - 1);

		for (; yearStart <= yearEnd; yearStart++) {
			this.years.push(yearStart);

			if (
				!!this._calendarDataService.getMinDate() &&
				this._calendarDataService.getMinDate().getFullYear() > yearStart
			) {
				this.disableDate.set(yearStart, true);
			} else if (
				!!this._calendarDataService.getMaxDate() &&
				this._calendarDataService.getMaxDate().getFullYear() < yearStart
			) {
				this.disableDate.set(yearStart, true);
			} else {
				this.disableDate.set(yearStart, false);
			}
		}

		if (this._calendarDataService.getSelectedPeriod() !== null) {
			this.initSelectedYears(
				this._calendarDataService.getSelectedPeriod()
			);
		}
	}

	private initMonth(year?: number): void {
		this.months = [];
		year = !!year
			? year
			: this._calendarDataService.getViewTime().getFullYear();

		for (let i = 0; i <= 11; i++) {
			this.months.push(i);

			const minDate = this._calendarDataService.getMinDate();
			const maxDate = this._calendarDataService.getMaxDate();

			if (
				!!minDate &&
				minDate.getMonth() + minDate.getFullYear() * 100 >
				i + year * 100
			) {
				this.disableDate.set(i, true);
			} else if (
				!!maxDate &&
				maxDate.getMonth() + maxDate.getFullYear() * 100 <
				i + year * 100
			) {
				this.disableDate.set(i, true);
			} else {
				this.disableDate.set(i, false);
			}
		}

		if (this._calendarDataService.getSelectedPeriod() !== null) {
			this.initSelectedMonths(
				this._calendarDataService.getSelectedPeriod()
			);
		}
	}

	private initSelectedYears(period: IPeriodYears): void {
		this.selectedPeriod = new Map();

		const funcCheck = (arr: number[], start: number, end: number) => {
			arr.forEach(item => {
				if (item === start && item === end) {
					this.selectedPeriod.set(item, 'selected first last');
				} else if (item === start) {
					this.selectedPeriod.set(item, 'selected first');
				} else if (item === end) {
					this.selectedPeriod.set(item, 'selected last');
				} else if (item > start && item < end) {
					this.selectedPeriod.set(item, 'selected');
				}
			});
		};

		const start = new Date(period.from).getFullYear();

		if (!period.to) {
			this.selectedPeriod.set(start, 'first last selected');
			return;
		}

		const end = new Date(period.to).getFullYear();
		funcCheck(this.years, start, end);
	}

	private initSelectedMonths(period: IPeriodYears): void {
		this.selectedPeriod = new Map();

		const startDate = new Date(period.from);
		const year = this._selectedDate.getFullYear();

		if (!period.to && !!period.from && startDate.getFullYear() === year) {
			this.selectedPeriod.set(
				startDate.getMonth(),
				'first last selected'
			);
			return;
		}
		const start = startDate.getFullYear() * 100 + startDate.getMonth();

		const endDate = new Date(period.to);
		const end = endDate.getFullYear() * 100 + endDate.getMonth();

		this.months.forEach(month => {
			const date = month + year * 100;

			if (date === start && date === end) {
				this.selectedPeriod.set(month, 'selected first last');
			} else if (date === start) {
				this.selectedPeriod.set(month, 'selected first');
			} else if (date === end) {
				this.selectedPeriod.set(month, 'selected last');
			} else if (date > start && date < end) {
				this.selectedPeriod.set(month, 'selected');
			}
		});
	}

	public choiceYear(year: number): void {
		this.viewMode = 'months';
		this._selectedDate.setFullYear(year);

		this._calendarDataService.setViewModes({
			dateMode: this.viewMode,
			calendarMode: 'date',
		});

		this._calendarDataService.setMonthsYear(year);
	}

	public choiceMonth(month: number): void {
		this._selectedDate.setMonth(month);

		this._calendarDataService.setViewModes({
			dateMode: this.viewMode,
			calendarMode: 'calendar',
		});

		this._calendarDataService.setViewTime(this._selectedDate);
	}

	protected readonly NAME_MONTH = NAME_MONTH;
}
