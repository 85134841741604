<div *ngIf="viewMode === 'years'" class="years">
	<div
		*ngFor="let year of years"
		class="year g-unselectable"
		[class]="selectedPeriod.get(year)"
		[class.disabled]="disableDate.get(year)"
	>
		<div class="item"
			 (click)="choiceYear(year)"
		>
			{{ year }}
		</div>
	</div>
</div>

<div *ngIf="viewMode === 'months'" class="months">
	<div
		*ngFor="let monthNumber of months"
		class="month g-unselectable"
		[class]="selectedPeriod.get(monthNumber)"
		[class.disabled]="disableDate.get(monthNumber)"
	>
		<div
			class="item"
			(click)="choiceMonth(monthNumber)"
		>
			{{ NAME_MONTH[monthNumber] }}
		</div>
	</div>
</div>
