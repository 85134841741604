<div
	class="button height-content"
	[class.disabled]="disableBtnPrev"
	(click)="prevYear()"
>
	<mat-icon
		svgIcon="chevron-right"
		class="rotate180"
	></mat-icon>
</div>

<div
	class="title"
	(click)="openViewYears()"
>
	{{ viewYear }}
</div>

<div
	class="button height-content"
	[class.disabled]="disableBtnNext"
	(click)="nextYear()"
>
	<mat-icon svgIcon="chevron-right"></mat-icon>
</div>
