import { NgModule } from '@angular/core';
import { NTimepickerComponent } from '@shared/ui-components/n-timepicker/n-timepicker.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [NTimepickerComponent],
	exports: [NTimepickerComponent],
	providers: [],
	imports: [CommonModule, ReactiveFormsModule],
})
export class NTimepickerModule {}
