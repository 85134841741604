export type templateType<M extends string | number | symbol = any, T = number> = {
  [s in M as M]: T;
};

export type NameMonth = 'Январь' | 'Февраль' | 'Март' | 'Апрель'| 'Май'  | 'Июнь' | 'Июль' | 'Август' | 'Сентябрь' | 'Октябрь' | 'Ноябрь' | 'Декабрь';
export type NameMonthAbbreviate = 'Янв' | 'Фев' | 'Март' | 'Апр'| 'Май'  | 'Июнь' | 'Июль' | 'Авг' | 'Сен' | 'Окт' | 'Нояб' | 'Дек';

export const NAME_MONTH: templateType<number, NameMonth> = {
  0: 'Январь',
  1: 'Февраль',
  2: 'Март',
  3: 'Апрель',
  4: 'Май',
  5: 'Июнь',
  6: 'Июль',
  7: 'Август',
  8: 'Сентябрь',
  9: 'Октябрь',
  10: 'Ноябрь',
  11: 'Декабрь',
}

export const NAME_MONTH_ABBREVIATE: templateType<number, NameMonthAbbreviate> = {
  0: 'Янв',
  1: 'Фев',
  2: 'Март',
  3: 'Апр',
  4: 'Май',
  5: 'Июнь',
  6: 'Июль',
  7: 'Авг',
  8: 'Сен',
  9: 'Окт',
  10: 'Нояб',
  11: 'Дек',
}

export const WEEK_DAYS: string[] = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

export const WIDTH_MATRIX_YEARS = 4;
export const HEIGHT_MATRIX_YEARS = 4;
