import {NgModule} from '@angular/core';
import {IntervalComponent} from '@shared/ui-components/n-inverval/interval.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {NTimepickerModule} from '@shared/ui-components/n-timepicker/n-timepicker.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CalendarModule} from '@shared/ui-components/n-calendar/calendar.module';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';

@NgModule({
	declarations: [IntervalComponent],
	exports: [IntervalComponent],
	providers: [],
	imports: [
		CommonModule,
		MatIconModule,
		NTimepickerModule,
		ReactiveFormsModule,
		CalendarModule,
		StopPropagationModule,
	],
})
export class IntervalModule {
}
