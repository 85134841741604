import {IShift} from '@shared/repositories/sdweb.repository';

export interface IPartialInterval {
	start?: Date;
	end?: Date;
}

export interface IIntervalString {
	start: string;
	end: string;
}

export interface IIntervalShift {
	name: string;
	workShifts: IShift[];
}

export type CalendarViewModule =
	| 'btn-period'
	| 'time'
	| 'date'
	| 'custom-period'
	| 'shift';
export type CalendarCustomPeriodTitle =
	| 'Свой период'
	| 'Последний час'
	| 'Сегодня'
	| 'Вчера'
	| 'Текущая неделя'
	| 'Прошлая неделя'
	| 'Текущий месяц'
	| 'Прошлый месяц';
export type TKeyOfIntervalPeriod =
	| 'customPeriod'
	| 'lastHour'
	| 'today'
	| 'yesterday'
	| 'week'
	| 'lastWeek'
	| 'month'
	| 'lastMonth';
export const CALENDAR_CUSTOM_PERIOD: TKeyOfIntervalPeriod[] = [
	'lastHour',
	'today',
	'yesterday',
	'week',
	'lastWeek',
	'month',
	'lastMonth',
];

export interface ICustomPeriodCalendar {
	title: CalendarCustomPeriodTitle;
	start: () => Date;
	end: () => Date;
}

export type IntervalSizeType = 'min' | 'middle' | 'max' | 'auto';
