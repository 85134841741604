import {Injectable} from '@angular/core';
import {CalendarDataService} from '@shared/ui-components/n-calendar/services/calendar-data.service';
import {IIntervalCheckmarks} from '@shared/ui-components/n-calendar/models/calendar.types';

@Injectable()
export class CheckmarksDataService {
	private _checkmarksMap: Map<number, number>;

	constructor(private _calendarDataService: CalendarDataService) {
		this._checkmarksMap = new Map();
	}

	public setCheckmarks(checkmarks: IIntervalCheckmarks[]): void {
		const fnSetMap = (date: Date) => {
			const key = this._calendarDataService.getNumberDate(date);
			this._checkmarksMap.set(key, 1);
		};

		checkmarks.forEach((checkmark: IIntervalCheckmarks) => {
			if (checkmark.date) {
				fnSetMap(checkmark.date);
			} else if (!!checkmark.interval) {
				const iDate = new Date(checkmark.interval.from);

				for (
					;
					iDate.getTime() <= checkmark.interval.to.getTime();
					iDate.setDate(iDate.getDate() + 1)
				) {
					fnSetMap(iDate);
				}
			}
		});
	}

	public getViewCheckmarks(
		viewDate: Date,
		previousViewDays: number[],
		viewDays: number[],
		nextViewDays: number[]
	): Map<string, boolean> {
		const viewCheckmarksMap: Map<string, boolean> = new Map();

		const prevMonth = new Date(viewDate);
		prevMonth.setMonth(prevMonth.getMonth() - 1);

		const currentMonth = new Date(viewDate);

		const nextMonth = new Date(viewDate);
		nextMonth.setMonth(nextMonth.getMonth() + 1);

		const fnSetMap = (day: number, title: string, month: Date) => {
			const date = day + month.getMonth() * 100 + month.getFullYear();
			const hasCheckmarks = this.hasCheckmark(date);
			viewCheckmarksMap.set(title + day, hasCheckmarks);
		};

		previousViewDays.forEach((day: number) => {
			fnSetMap(day, 'prev', prevMonth);
		});

		viewDays.forEach((day: number) => {
			fnSetMap(day, 'current', currentMonth);
		});

		nextViewDays.forEach((day: number) => {
			fnSetMap(day, 'next', nextMonth);
		});

		return viewCheckmarksMap;
	}

	public getCheckmarksMap(): Map<number, number> {
		return this._checkmarksMap;
	}

	public hasCheckmark(key: number): boolean {
		return this._checkmarksMap.has(key);
	}
}
