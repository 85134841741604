import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IntervalUtils} from '@shared/ui-components/n-inverval/services/interval.utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
	CALENDAR_CUSTOM_PERIOD,
	CalendarViewModule, ICustomPeriodCalendar,
	IIntervalShift,
	IntervalSizeType,
	IPartialInterval,
	TKeyOfIntervalPeriod,
} from '@shared/ui-components/n-inverval/models/interval.types';
import {NTimepickerComponent} from '@shared/ui-components/n-timepicker/n-timepicker.component';
import {getCustomScaleAppearanceAnimation} from '@shared/animations/common.animations';
import {IntervalService} from '@shared/ui-components/n-inverval/services/interval.service';
import {debounceTime, filter, mergeAll} from 'rxjs/operators';
import {combineLatest, of} from 'rxjs';
import {CustomPeriodConfig, CustomPeriodConfigWithMaxDate} from '@shared/ui-components/n-inverval/models/config';
import {IShift, ITypeShift} from '@shared/repositories/sdweb.repository';
import {getShiftLabel} from '../../../modules/sd/modules/schedule/services/shedule.utils';
import {CalendarDirective} from '@shared/ui-components/n-calendar/directives/calendar.directive';
import {ModeChoiceType} from '@shared/ui-components/n-calendar/models/calendar.types';
import {nextTick} from '@shared/utils';
import { ShiftSelector } from '@store/shift-store/shift.selector';
import { definitionShiftByCurrentTime } from '@shared/ui-components/n-inverval/tools/interval.tools';


@UntilDestroy()
@Component({
	selector: 'app-interval',
	templateUrl: 'interval.component.html',
	styleUrls: ['interval.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [getCustomScaleAppearanceAnimation()],
})
export class IntervalComponent implements OnInit {
	@ViewChild(CalendarDirective) triggerCalendar: CalendarDirective;
	@ViewChildren(NTimepickerComponent)
	timepickers: QueryList<NTimepickerComponent>;

	@Input() withTime = true;
	@Input() formatDate = 'dd.MM.yyyy';

	@Input() fromDate: Date;
	@Input() toDate: Date;

	@Input() minDate: Date = null;
	@Input() maxDate: Date = null;
	@Input() maxDateCurrentDate = false;

	@Input() viewModule: CalendarViewModule[] = [
		'btn-period',
		'time',
		'date',
		'custom-period',
		'shift'
	];
	@Input() nameInterval = 'common';
	@Input() defaultCustomPeriod: TKeyOfIntervalPeriod | null = null;
	@Input() priorityInitial: 'customPeriod' | 'inputDate' | null = null;
	@Input() size: IntervalSizeType = 'auto';

	@Input() omegaMode = true; //  переведит интервал на сутки 00:00 23:59 если день, час и минуты старта и конца одинаковы

	@Output() changeValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changeInterval: EventEmitter<IPartialInterval> =
		new EventEmitter<IPartialInterval>();

	public fromDateControl: FormControl<Date>;
	public toDateControl: FormControl<Date>;
	public viewModeMap: Map<CalendarViewModule, boolean>;

	public customPeriod: TKeyOfIntervalPeriod[];
	public selectedCustomPeriod: TKeyOfIntervalPeriod;
	public customPeriodConfig: Record<TKeyOfIntervalPeriod, ICustomPeriodCalendar>;
	public isViewList: 'period' | 'shift' | false;

	public selectedShift: IShift;
	public intervalShifts: IIntervalShift[];

	public date: Date;
	public modeCalendar: ModeChoiceType;
	public isValid: boolean;

	public calendarMaxDate: Date | null;
	public calendarMinDate: Date | null;
	public posElement: HTMLElement;

	private _selectedTypeCalendar: 'from' | 'to' | 'interval';

	constructor(
		private intervalDataService: IntervalUtils,
		private _intervalService: IntervalService,
		private _elementRef: ElementRef,
		private _cdr: ChangeDetectorRef,
		private _shiftSelector: ShiftSelector,
	) {
		this.viewModeMap = new Map();
		this.customPeriod = CALENDAR_CUSTOM_PERIOD;
		this.selectedCustomPeriod = 'customPeriod';
		this.customPeriodConfig = CustomPeriodConfig;
		this.isViewList = false;

		this.selectedShift = null;
		this.intervalShifts = [];

		this.modeCalendar = 'interval';
		this.isValid = true;

		this.calendarMinDate = null;
		this.calendarMaxDate = null;
	}

	public ngOnInit(): void {
		this.initValues();
		this.initListeners();

		this.changeDate();
	}

	private initValues(): void {
		this.viewModule.forEach(module => {
			this.viewModeMap.set(module, true);
		});

		if (this.maxDateCurrentDate) {
			this.maxDate = this.intervalDataService.setEndDay(
				new Date(Date.now()),
			);
		} else {
			this.customPeriodConfig = CustomPeriodConfigWithMaxDate;
		}

		this.initInterval();

		this.fromDateControl = new FormControl(this.fromDate);
		this.toDateControl = new FormControl(this.toDate);
	}

	initInterval(): void {
		const interval = this._intervalService.getIntervalValue(this.nameInterval);
		const period = this._intervalService.getCustomPeriod(this.nameInterval);

		if (!!period && period !== 'customPeriod') {
			this.initByCustomPeriod(period);
		} else if (!!interval?.start) {
			this.fromDate = interval.start;
			this.toDate = interval.end;
		} else if (!this.defaultCustomPeriod) {
			if (!this.fromDate) {
				this.fromDate = this.intervalDataService.setBeginDay(
					new Date(Date.now()),
				);
			}

			if (!this.toDate) {
				this.toDate = this.intervalDataService.setEndDay(
					new Date(Date.now()),
				);
			}
		} else {
			this.initByCustomPeriod(this.defaultCustomPeriod);
		}
	}

	private initByCustomPeriod(period: TKeyOfIntervalPeriod): void {
		this.selectedCustomPeriod = period;
		this.fromDate =
			this.customPeriodConfig[this.selectedCustomPeriod].start();
		this.toDate = this.customPeriodConfig[this.selectedCustomPeriod].end();
	}

	private initListeners(): void {
		if (this.viewModeMap.has('shift')) {
			const typeShifts$ = this._shiftSelector.select('typeShifts');
			const workShifts$ = this._shiftSelector.select('workShifts');

			combineLatest([
				typeShifts$,
				workShifts$,
			]).pipe(
				untilDestroyed(this)
			)
				.subscribe(([typeShifts, workShifts]: [ITypeShift[], IShift[]]) => {
					const map: Map<number, IShift[]> = new Map();
					workShifts.forEach((item) => {
						if (map.has(item.shiftTypeId)) {
							map.get(item.shiftTypeId).push(item);
						} else {
							map.set(item.shiftTypeId, [item]);
						}
					});

					const haveSavedInterval =
						!!this._intervalService.getIntervalValue()?.start
						|| !!this._intervalService.getCustomPeriod(this.nameInterval);
					let defaultShiftId = null;

					this.intervalShifts = typeShifts.map((type: ITypeShift) => {
						if (defaultShiftId === null && type.isDefault && !haveSavedInterval) {
							defaultShiftId = type.id;
						}

						return {
							name: type.name,
							workShifts: map.get(type.id).sort((one, two) => one.order - two.order),
						}
					});

					if (defaultShiftId) {
						this.definitionDefaultShift(map.get(defaultShiftId));
					}
				});
		}

		const from$ = this.fromDateControl.valueChanges;
		const to$ = this.toDateControl.valueChanges;

		of(from$, to$)
			.pipe(mergeAll(), debounceTime(1))
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				if (this.maxDateCurrentDate) {
					this.maxDate = new Date(Date.now());
				}

				if (
					!!this.maxDate &&
					this.toDateControl.value.getTime() > this.maxDate.getTime()
				) {
					this.isValid = false;
				} else {
					this.isValid =
						this.fromDateControl.value.getTime() <=
						this.toDateControl.value.getTime();
				}

				if (this.isValid && this.omegaMode) {
					this.userOmegaMode();
				}

				this.changeValid.emit(this.isValid);
				this._cdr.markForCheck();
				this.changeDate();
			});

		if (this.size === 'auto') {
			setTimeout(() => {
				this.definitionSize();
			});
			window.onresize = value => {
				this.definitionSize();
			};
		}
	}

	private definitionSize(): void {
		const width = (this._elementRef.nativeElement as HTMLElement)
			.clientWidth;
		const prevSize = this.size;

		if (width < 420) {
			this.size = 'min';
		} else if (width < 550) {
			this.size = 'middle';
		} else {
			this.size = 'max';
		}

		if (prevSize !== this.size) {
			this._cdr.markForCheck();
		}
	}

	@HostListener('document:click', ['$event'])
	public clickOutside(): void {
		if (this.isViewList) {
			this.isViewList = false;
		}
	}

	public changeTimePicker(event: any): void {
		this.selectedCustomPeriod = 'customPeriod';
		this.selectedShift = null;
		this._intervalService.setCustomPeriod(this.selectedCustomPeriod);
	}

	public changeCalendar(date: Date[]): void {
		if (this.modeCalendar === 'interval') {
			this.changeCalendarDate(date);

			if (this.viewModeMap.has('time')) {
				this.timepickers.get(0).setFocus();
			}
		} else if (this._selectedTypeCalendar === 'from') {
			this.changeCalendarFromDate(date);
			this.closeCalendar();
			if (this.viewModeMap.has('time')) {
				this.timepickers.get(0).setFocus();
			}
		} else if (this._selectedTypeCalendar === 'to') {
			this.changeCalendarToDate(date);
			this.closeCalendar();
			if (this.viewModeMap.has('time')) {
				this.timepickers.get(1).setFocus();
			}
		}

		this.selectedCustomPeriod = 'customPeriod';
		this.selectedShift = null;
		this._intervalService.setCustomPeriod(this.selectedCustomPeriod);
		this.changeDate();
	}

	private changeCalendarDate(date: Date[]): void {
		const tmpFromDate = this._getCopyDate(this.fromDateControl.value);
		const tmpToDate = this._getCopyDate(this.toDateControl.value);

		this.fromDate = this._getCopyDate(date[0]);
		this.toDate = this._getCopyDate(date[1]);

		this.fromDate.setHours(
			tmpFromDate.getHours(),
			tmpFromDate.getMinutes(),
			tmpFromDate.getSeconds(),
			tmpFromDate.getMilliseconds(),
		);
		this.toDate.setHours(
			tmpToDate.getHours(),
			tmpToDate.getMinutes(),
			tmpToDate.getSeconds(),
			tmpToDate.getMilliseconds(),
		);

		this.fromDateControl.setValue(this.fromDate);
		this.toDateControl.setValue(this.toDate);
	}

	private changeCalendarFromDate(date: Date[]): void {
		const tmpFromDate = this._getCopyDate(this.fromDateControl.value);

		this.fromDate = this._getCopyDate(date[0]);
		this.date = this._getCopyDate(date[0]);

		this.fromDate.setHours(
			tmpFromDate.getHours(),
			tmpFromDate.getMinutes(),
			tmpFromDate.getSeconds(),
			tmpFromDate.getMilliseconds(),
		);
		this.date.setHours(
			tmpFromDate.getHours(),
			tmpFromDate.getMinutes(),
			tmpFromDate.getSeconds(),
			tmpFromDate.getMilliseconds(),
		);

		this.fromDateControl.setValue(this.fromDate);
	}

	private changeCalendarToDate(date: Date[]): void {
		const tmpToDate = this._getCopyDate(this.toDateControl.value);

		this.toDate = this._getCopyDate(date[0]);
		this.date = this._getCopyDate(date[0]);

		this.toDate.setHours(
			tmpToDate.getHours(),
			tmpToDate.getMinutes(),
			tmpToDate.getSeconds(),
			tmpToDate.getMilliseconds(),
		);
		this.date.setHours(
			tmpToDate.getHours(),
			tmpToDate.getMinutes(),
			tmpToDate.getSeconds(),
			tmpToDate.getMilliseconds(),
		);

		this.toDateControl.setValue(this.toDate);
	}

	public setPeriod(periodKey: TKeyOfIntervalPeriod): void {
		this.openList('period');
		this.selectedShift = null;
		this.selectedCustomPeriod = periodKey;
		if (periodKey === 'customPeriod') {
			return;
		}

		this._intervalService.setCustomPeriod(this.selectedCustomPeriod);

		this.fromDate = this.customPeriodConfig[periodKey].start();
		this.toDate = this.customPeriodConfig[periodKey].end();

		this.fromDateControl.setValue(this.fromDate);
		this.toDateControl.setValue(this.toDate);
		this.changeDate();
	}

	public selectShift(shift: IShift): void {
		this.openList('shift');
		this.setShift(shift);
	}

	public openList(typeView: 'period' | 'shift' | false): void {
		if (this.triggerCalendar.isOpened()) {
			this.triggerCalendar.destroyPopoverComponent();
		}
		this.isViewList = this.isViewList === typeView ? false : typeView;
	}

	public openCalendar(
		event: MouseEvent,
		modeCalendar: ModeChoiceType,
		el: HTMLElement,
		type: 'interval' | 'from' | 'to',
	): void {
		event.stopPropagation();

		if (type === 'interval') {
			this.calendarMinDate = this._getMinDate();
			this.calendarMaxDate = this._getMaxDate();
			this.date = null;
		} else if (type === 'from') {
			this.calendarMaxDate = !this.toDate
				? this._getMaxDate()
				: this._getCopyDate(this.toDate);
			this.calendarMinDate = this._getMinDate();
			this.date = this._getCopyDate(this.fromDate);
		} else if (type === 'to') {
			this.calendarMinDate = !this.fromDate
				? this._getMinDate()
				: this._getCopyDate(this.fromDate);
			this.calendarMaxDate = this._getMaxDate();
			this.date = this._getCopyDate(this.toDate);
		}

		this._selectedTypeCalendar = type;

		this.posElement = el;

		this.isViewList = false;
		this.modeCalendar = modeCalendar;
		this.triggerCalendar.callCalendar();
	}

	private definitionDefaultShift(shifts: IShift[]): void {
		const shift = definitionShiftByCurrentTime(shifts);

		if (!!shifts) {
			setTimeout(() => {
				this.fromDate = new Date(Date.now());
				this.toDate = this.intervalDataService.setEndDay(new Date(Date.now()),);
				this.setShift(shift);
			});
		}
	}

	private setShift(shift: IShift): void {
		this.selectedCustomPeriod = 'customPeriod';

		const start = shift.start.split(':');
		const end = shift.end.split(':');

		this.selectedShift = shift;

		const newFromDate = new Date(this.fromDate);
		const newToDate = new Date(newFromDate);

		newFromDate.setHours(Number(start[0]), Number(start[1]), Number(start[2]));
		newToDate.setHours(Number(end[0]), Number(end[1]), Number(end[2]));

		this._dateCorrectionByShifts(newFromDate, newToDate);

		this.fromDate = newFromDate;
		this.toDate = newToDate;

		this.fromDateControl.setValue(this.fromDate);
		this.toDateControl.setValue(this.toDate);
		this.changeDate();
	}

	private closeCalendar(): void {
		if (this.triggerCalendar.isOpened()) {
			this.triggerCalendar.destroyPopoverComponent();
		}
	}

	private userOmegaMode(): void {
		const fromDate = new Date(this.fromDateControl.value);
		const toDate = new Date(this.toDateControl.value);

		const getSumDate = (date: Date) =>
			' ' +
			date.getFullYear() +
			date.getMonth() +
			date.getDate() +
			date.getHours() +
			date.getMinutes();
		const getSumDateAroundDay = (date: Date) =>
			' ' + date.getFullYear() + date.getMonth() + date.getDate();

		if (getSumDate(fromDate) === getSumDate(toDate)) {
			fromDate.setHours(0, 0, 0, 0);

			this.fromDateControl.setValue(fromDate);
			if (
				getSumDateAroundDay(fromDate) ===
				getSumDateAroundDay(new Date(Date.now()))
			) {
				this.toDateControl.setValue(new Date(Date.now()));
			} else {
				toDate.setHours(23, 59, 59, 999);
				this.toDateControl.setValue(toDate);
			}
		}
	}

	private _dateCorrectionByShifts(fromDate: Date, toDate: Date): void {
		const dateNow = new Date(Date.now());

		if (fromDate.getTime() >= toDate.getTime()) {
			const dateNowWithoutTime = dateNow.getDate() + dateNow.getMonth() * 100 + dateNow.getFullYear() * 1000;
			const newFromDateWithoutTime = fromDate.getDate() + fromDate.getMonth() * 100 + fromDate.getFullYear() * 1000;

			if (dateNowWithoutTime === newFromDateWithoutTime && dateNow.getHours() > 0 && dateNow.getHours() < toDate.getHours()) {
				fromDate.setDate(fromDate.getDate() - 1);
			} else if (dateNowWithoutTime === newFromDateWithoutTime && dateNow.getHours() > 0 && dateNow.getHours() > fromDate.getHours()) { // ЕСЛИ ТЕКУЩИЙ ДЕНЬ ЕЩЕ НЕ ЗАКОНЧЕН, А СМЕНА КОНЧАЕТСЯ НА СЛЕДУЮЩИЙ ДЕНЬ
				toDate.setTime(Date.now());
			} else {
				toDate.setDate(toDate.getDate() + 1);
			}
		}

		if (this.maxDateCurrentDate) {
			if (fromDate.getTime() >= dateNow.getTime()) {
				fromDate.setTime(dateNow.getTime() - 60000);
			}

			if (toDate.getTime() >= dateNow.getTime()) {
				toDate.setTime(dateNow.getTime() - 1);
			}
		}
	}

	private changeDate(): void {
		const data = {
			start: this.fromDate,
			end: this.toDate,
		};

		this._intervalService.setInterval(data, this.nameInterval);
		this.changeInterval.emit(data);
	}

	private _getMaxDate(): Date {
		return !!this.maxDate ? new Date(this.maxDate) : this.maxDate;
	}

	private _getMinDate(): Date {
		return !!this.minDate ? new Date(this.minDate) : this.minDate;
	}

	private _getCopyDate(date: Date): Date {
		return new Date(date.getTime());
	}


	protected readonly getShiftLabel = getShiftLabel;
}
