import {NgModule} from '@angular/core';
import {CalendarComponent} from './calendar.component';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MonthsPaginatorComponent} from '@shared/ui-components/n-calendar/components/months-paginator/months-paginator.component';
import {SelectDateComponent} from '@shared/ui-components/n-calendar/components/select-date/select-date.component';
import {YearsPaginatorComponent} from '@shared/ui-components/n-calendar/components/years-paginator/years-paginator.component';
import {CalendarHeaderComponent} from '@shared/ui-components/n-calendar/components/calendar-header/calendar-header.component';
import {YearPaginatorComponent} from '@shared/ui-components/n-calendar/components/year-paginator/year-paginator.component';
import {CalendarDirective} from '@shared/ui-components/n-calendar/directives/calendar.directive';

@NgModule({
	declarations: [
		CalendarComponent,
		MonthsPaginatorComponent,
		SelectDateComponent,
		YearsPaginatorComponent,
		CalendarHeaderComponent,
		CalendarDirective,
		YearPaginatorComponent,
	],
	exports: [CalendarComponent, CalendarDirective],
	imports: [CommonModule, RouterLink, ReactiveFormsModule, MatIconModule],
})
export class CalendarModule {
}
