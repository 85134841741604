import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {distinctUntilChanged} from 'rxjs/operators';
import {NumberValidator} from '@shared/utils/form-control-validators';
import {ITimepickerFormGroup} from '@shared/ui-components/n-timepicker/models/n-timepicker.types';

@UntilDestroy()
@Component({
	selector: 'app-n-timepicker',
	templateUrl: 'n-timepicker.component.html',
	styleUrls: ['n-timepicker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => NTimepickerComponent),
		multi: true
	}],
})
export class NTimepickerComponent implements OnInit, ControlValueAccessor {
	@Input() date: Date | null;
	@Output() changeDate: EventEmitter<Date | null> =
		new EventEmitter<Date | null>();

	public group: FormGroup;

	private onChange(_: any) {
	}

	private onTouch(_: any) {
	}

	constructor(private _el: ElementRef) {
	}

	public ngOnInit(): void {
		this.initGroup();
		this.setValue(this.date);
		this.initListeners();
	}

	private  initGroup(): void {
		this.group = new FormGroup<ITimepickerFormGroup>({
			hours: new FormControl<string>(''),
			minutes: new FormControl<string>(''),
		});

		this.group.get('hours').setValidators(NumberValidator(23, 0, true, 2));
		this.group.get('minutes').setValidators(NumberValidator(59, 0, true, 2));
	}

	private initListeners(): void {
		this.group.valueChanges
			.pipe(
				untilDestroyed(this),
				distinctUntilChanged(this.compareForm)
			)
			.subscribe((res) => {
				this.date.setMinutes(res.minutes);
				this.date.setHours(res.hours);
			});
	}

	private setValue(date: Date | null): void {
		if (!!date && date instanceof Date) {
			this.date = date;
		} else {
			this.date = null;
		}

		this.setGroup(this.date);
	}

	public changeValue(): void {
		this.changeDate.emit(this.date);
		this.onChange(this.date);
	}

	public useMask(field: string): void {
		const value = this.group.get(field).value;

		if (value.length === 1) {
			this.group.get(field).setValue('0' + value);
		}
	}

	// tslint:disable-next-line:max-line-length
	// Метод необходим, чтобы фокусировать ввод после закрытия календаря в компоненте интервал
	public setFocus(indexInterval: number = 0): void {
		const input = (this._el.nativeElement as HTMLElement).getElementsByTagName('input').item(indexInterval);

		input.select();
	}

	private setGroup(date: Date): void {
		let hours = '';
		let minutes = '';

		if (!!date && date instanceof Date) {
			hours = date.getHours().toString();
			minutes = date.getMinutes().toString();

			if (hours.length === 1) {
				hours = '0' + hours;
			}
			if (minutes.length === 1) {
				minutes = '0' + minutes;
			}
		}

		this.group.setValue({
			hours: hours,
			minutes: minutes,
		});
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this.group.disable();
		} else {
			this.group.enable();
		}
	}

	public writeValue(obj: any): void {
		this.setValue(obj);
	}

	private compareForm(one: any, two: any): boolean {
		return one.hours === two.hours && one.minutes === two.minutes;
	}
}
