<div class="button height-content"
	 [class.disabled]="disableBtnPrev"
	 (click)="prevPeriod()">
	<mat-icon svgIcon="chevron-right" class="rotate180"></mat-icon>
</div>

<div class="title">
	{{ yearsPeriod }}
</div>

<div class="button height-content"
	 [class.disabled]="disableBtnNext"
	 (click)="nextPeriod()">
	<mat-icon svgIcon="chevron-right"></mat-icon>
</div>
