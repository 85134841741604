<div
	class="interval"
	appCalendar
	[componentPosition]="posElement"
	[calendarTemplate]="calendar"
	[withoutClick]="true"
	[class.invalid]="!isValid"
	[class]="size"
>
	@if (viewModeMap.has('btn-period')) {
		<button
			class="btn-interval"
			#btnInterval
			(click)="openCalendar($event, 'interval', btnInterval, 'interval')"
		>
			<mat-icon svgIcon="planning"></mat-icon>
		</button>

		<div class="gr"></div>
	}

	<div class="container-date">
		@if (viewModeMap.has('date')) {
			<div
				class="container-date__date"
				#from
				(click)="openCalendar($event, 'single', from, 'from')"
			>
				{{ fromDate | date: formatDate }}
			</div>
		}

		@if (viewModeMap.has('time')) {
			<app-n-timepicker
				class="timepicker"
				[formControl]="fromDateControl"
				(changeDate)="changeTimePicker($event)"
			></app-n-timepicker>
		}
	</div>

	<div class="dash"></div>

	<div class="container-date">
		@if (viewModeMap.has('date')) {
			<div
				class="container-date__date"
				#to
				(click)="openCalendar($event, 'single', to, 'to')"
			>
				{{ toDate | date: formatDate }}
			</div>
		}

		@if (viewModeMap.has('time')) {
			<app-n-timepicker
				class="timepicker"
				[formControl]="toDateControl"
				(changeDate)="changeTimePicker($event)"
			></app-n-timepicker>
		}
	</div>
</div>

<ng-template #calendar>
	<app-calendar @scaleAppearanceAnimation
				  class="box-shadow-4"
				  [modeChoice]="modeCalendar"
				  [fromDate]="fromDate"
				  [toDate]="toDate"
				  [date]="date"
				  [maxDate]="calendarMaxDate"
				  [minDate]="calendarMinDate"
				  (changeDate)="changeCalendar($event)"
	></app-calendar>
</ng-template>

<div
	appStopPropagation
	class="custom-period"
>
	<div
		class="custom-period__title"
		(click)="openList('period')"
	>
		{{ customPeriodConfig[selectedCustomPeriod].title }}
	</div>

	@if (isViewList === 'period') {
		<div
			@scaleAppearanceAnimation
			class="custom-period__list"
		>
			@for (period of customPeriod; track period) {
				<div
					class="custom-period__list__item"
					(click)="setPeriod(period)"
				>
					{{ customPeriodConfig[period].title }}
				</div>
			}
		</div>
	}
</div>

<div
	*ngIf="viewModeMap.has('shift')"
	appStopPropagation
	class="custom-period"
>
	<div
		class="custom-period__title"
		(click)="openList('shift')"
	>
		{{ selectedShift === null ? 'Выбрать смену' : getShiftLabel(selectedShift) }}
	</div>

	@if (isViewList === 'shift') {
		<div
			@scaleAppearanceAnimation
			class="custom-period__list"
		>
			@for (shifts of intervalShifts; track shifts; let i = $index) {
				<div class="title mb-4">{{ shifts.name }}</div>

				@for (shift of shifts.workShifts; track shift) {
					<div class="custom-period__list__item"
						 (click)="selectShift(shift)"
					>
						{{ getShiftLabel(shift) }}
					</div>
				}

				<hr *ngIf="i !== intervalShifts.length - 1" class="full-width g-background-light">
			}
		</div>
	}
</div>

