import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,} from '@angular/core';
import {CalendarDataService} from './services/calendar-data.service';
import {WEEK_DAYS} from './models/calendar.constants';
import {CheckmarksDataService} from './services/checkmarks-data.service';
import {MonthsPaginatorComponent} from '@shared/ui-components/n-calendar/components/months-paginator/months-paginator.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IIntervalCheckmarks, ModeChoiceType, ViewModeCalendar, ViewModeDate} from '@shared/ui-components/n-calendar/models/calendar.types';


@UntilDestroy()
@Component({
	selector: 'app-calendar',
	templateUrl: 'calendar.component.html',
	styleUrls: ['calendar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CalendarDataService, CheckmarksDataService],
})
export class CalendarComponent implements OnInit, OnChanges {
	@ViewChild(MonthsPaginatorComponent) selectMonth: MonthsPaginatorComponent;

	@Input() maxDate: Date | null = null;
	@Input() minDate: Date | null = null;
	@Input() checkmarks: IIntervalCheckmarks[] = [];
	@Input() modeChoice: ModeChoiceType = 'single';
	@Input() date: Date | null;
	@Input() fromDate: Date | null = null;
	@Input() toDate: Date | null = null;

	@Output() changeDate: EventEmitter<Date[]> = new EventEmitter<Date[]>();
	@Output() close: EventEmitter<void> = new EventEmitter();

	public previousViewDays: number[];
	public nextViewDays: number[];
	public viewDays: number[];

	public viewDate: Date;

	public weekDays: string[];
	public viewModeCalendar: ViewModeCalendar;
	public viewModeDate: ViewModeDate;

	public viewCheckmarksMap: Map<string, boolean>;
	public periodMap: Map<string, string>;

	private _selectedDatesMap: Map<number, Date>;
	private _tmpSelectedDatesMap: Map<number, Date>;
	private _isSelecting: boolean;

	constructor(
		public calendarDataService: CalendarDataService,
		private _checkmarksDataService: CheckmarksDataService,
		private _changeDetectorRef: ChangeDetectorRef
	) {
		this.initProperties();
	}

	private initProperties(): void {
		this.previousViewDays = [];
		this.nextViewDays = [];
		this.viewDays = [];

		this.weekDays = WEEK_DAYS;

		this.viewModeCalendar = 'calendar';
		this.viewModeDate = 'months';

		this.viewCheckmarksMap = new Map();
		this.periodMap = new Map();

		this._selectedDatesMap = new Map();
		this._tmpSelectedDatesMap = new Map();
		this._isSelecting = false;
	}

	public ngOnInit(): void {
		this.calendarDataService.setMinDate(this.minDate);
		this.calendarDataService.setMaxDate(this.maxDate);

		this.initValues();
		this.initListeners();
		this.calendarDataService.setViewTime(this.viewDate);
	}

	public ngOnChanges(): void {
		this._checkmarksDataService.setCheckmarks(this.checkmarks);
	}

	private initValues(): void {
		if (this.modeChoice === 'interval' && this.fromDate) {
			this.viewDate = new Date(this.fromDate);

			this.calendarDataService.setSelectedPeriod({
				from: this.fromDate.getTime(),
				to: !!this.toDate ? this.toDate.getTime() : null,
			});

			if (this.toDate) {
				this._selectedDatesMap.set(1, this.fromDate);
				this._selectedDatesMap.set(2, this.toDate);
			} else {
				this._tmpSelectedDatesMap.set(1, this.fromDate);
			}
		} else if (this.date) {
			this.viewDate = new Date(this.date);
			this._tmpSelectedDatesMap.set(1, this.date);
			this._selectedDatesMap.set(1, this.date);
			this.calendarDataService.setSelectedPeriod({
				from: this.date.getTime(),
				to: null,
			});
		} else {
			this.viewDate = new Date(Date.now());
		}

		this.viewDate.setDate(1);
		this.updateView();
	}

	private initListeners(): void {
		this.calendarDataService
			.getViewModesListener()
			.pipe(untilDestroyed(this))
			.subscribe(viewMode => {
				this.viewModeDate = viewMode.dateMode;
				this.viewModeCalendar = viewMode.calendarMode;
			});

		this.calendarDataService
			.getViewTimeListener()
			.pipe(untilDestroyed(this))
			.subscribe(viewDate => {
				this.viewDate = viewDate;
				this.updateView();
			});
	}

	private updateView(): void {
		this.updatePreviousViewDays();
		this.updateViewDays();
		this.updateNextViewDays();
		this.updatePeriodView();

		if (!!this.checkmarks.length) {
			this.updateViewCheckmarks();
		}

		this._changeDetectorRef.markForCheck();
	}

	public changeMode(viewMode: ViewModeCalendar, viewModeDate: ViewModeDate): void {
		this.viewModeCalendar = viewMode;
		this.viewModeDate = viewModeDate;
	}

	public setDate(date: Date): void {
		this.viewDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
		this.viewModeCalendar = 'calendar';
		this.viewModeDate = 'years';
	}

	public choiceDate(dateMonth: number): void {
		const date = new Date(this.viewDate);
		date.setDate(dateMonth);

		this.choiceDateHandler(date);
	}

	public choiceDateNextMonth(dateMonth: number): void {
		const date = new Date(this.viewDate);
		date.setMonth(date.getMonth() + 1);
		date.setDate(dateMonth);

		this.choiceDateHandler(date);
	}

	public choiceDatePrevMonth(dateMonth: number): void {
		const date = new Date(this.viewDate);
		date.setMonth(date.getMonth() - 1);
		date.setDate(dateMonth);

		this.choiceDateHandler(date);
	}

	private emitChangeDate(): void {
		const selectedDates: Date[] = [];
		this._selectedDatesMap.forEach((selectedDate: Date) => {
			selectedDates.push(selectedDate);
		});

		this.changeDate.emit(selectedDates);
	}

	private updatePeriodView(): void {
		this.periodMap.clear();

		if (!!this._tmpSelectedDatesMap.has(1)) {
			const date = this._tmpSelectedDatesMap.get(1);

			let prefix: string;
			let comparisonDate = new Date(this.viewDate);

			if (date.getMonth() === this.viewDate.getMonth() - 1) {
				prefix = 'prev';
				comparisonDate.setMonth(comparisonDate.getMonth() - 1);
			} else if (date.getMonth() === this.viewDate.getMonth() + 1) {
				prefix = 'next';
				comparisonDate.setMonth(comparisonDate.getMonth() + 1);
			} else {
				prefix = 'current';
			}

			if (
				date.getMonth() === comparisonDate.getMonth() &&
				date.getFullYear() === comparisonDate.getFullYear()
			) {
				this.periodMap.set(
					prefix + date.getDate(),
					'selected first-selected last-selected'
				);
			}

			return;
		}

		const startDate = this.getNumberDate(this._selectedDatesMap.get(1));
		const endDate = this.getNumberDate(this._selectedDatesMap.get(2));

		let indexDate = new Date(this.viewDate);

		let checkedStartDate = false;
		let checkedEndDate = false;

		const checkFunc = (dates: number[], prefix: string) => {
			let i = 0;
			while (i < dates.length) {
				if (checkedEndDate && checkedStartDate) {
					break;
				}
				const date = dates[i];

				indexDate.setDate(date);
				const dateNumber = this.getNumberDate(indexDate);
				let classes = '';

				if (dateNumber === startDate && dateNumber === endDate) {
					classes += 'first-selected last-selected selected';
					checkedStartDate = true;
				} else if (dateNumber === startDate) {
					classes += 'first-selected selected';
					checkedEndDate = true;
				} else if (dateNumber === endDate) {
					classes += 'last-selected selected';
					checkedEndDate = true;
				} else if (dateNumber > startDate && dateNumber < endDate) {
					classes += 'selected';
				}

				this.periodMap.set(prefix + date, classes);

				++i;
			}
		};

		indexDate.setMonth(indexDate.getMonth() - 1);
		checkFunc(this.previousViewDays, 'prev');

		indexDate = new Date(this.viewDate);
		checkFunc(this.viewDays, 'current');

		indexDate = new Date(this.viewDate);
		indexDate.setMonth(indexDate.getMonth() + 1);
		checkFunc(this.nextViewDays, 'next');
	}

	private updateViewCheckmarks(): void {
		this.viewCheckmarksMap = this._checkmarksDataService.getViewCheckmarks(
			this.viewDate,
			this.previousViewDays,
			this.viewDays,
			this.nextViewDays
		);
	}

	private updatePreviousViewDays(): void {
		this.previousViewDays = this.calendarDataService.getPreviousViewDays(
			this.viewDate
		);
	}

	private updateNextViewDays(): void {
		this.nextViewDays = this.calendarDataService.getNextViewDays(
			this.viewDate
		);
	}

	private updateViewDays(): void {
		this.viewDays = this.calendarDataService.getViewDays(new Date(this.viewDate));
	}

	private getNumberDate(date: Date): number | null {
		if (!date) {
			return null;
		}
		return (
			date.getDate() + date.getMonth() * 100 + date.getFullYear() * 10000
		);
	}

	private choiceDateHandler(date: Date): void {
		this._selectedDatesMap.clear();

		if (this.modeChoice === 'single') {
			this._tmpSelectedDatesMap.set(1, date);
			this._selectedDatesMap.set(1, date);
			this.calendarDataService.setSelectedPeriod({
				from: date.getTime(),
				to: null,
			});
			this.emitChangeDate();
		} else {
			if (!this._isSelecting) {
				this._tmpSelectedDatesMap.set(1, date);
				this._isSelecting = true;

				this.calendarDataService.setSelectedPeriod({
					from: date.getTime(),
					to: null,
				});
			} else {
				if (
					this.getNumberDate(
						this._tmpSelectedDatesMap.get(1) as Date
					) > this.getNumberDate(date)
				) {
					this._selectedDatesMap.set(1, date);
					this._selectedDatesMap.set(
						2,
						this._tmpSelectedDatesMap.get(1)
					);
				} else {
					this._selectedDatesMap.set(
						1,
						this._tmpSelectedDatesMap.get(1) as Date
					);
					this._selectedDatesMap.set(2, date);
				}

				this._isSelecting = false;
				this._tmpSelectedDatesMap.clear();
				this.calendarDataService.setSelectedPeriod({
					from: this._selectedDatesMap.get(1).getTime(),
					to: this._selectedDatesMap.get(2).getTime(),
				});
				this.emitChangeDate();
			}
		}

		this.updatePeriodView();
	}
}
