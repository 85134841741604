import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CalendarDataService } from '@shared/ui-components/n-calendar/services/calendar-data.service';

@Component({
	selector: 'app-year-paginator',
	templateUrl: 'year-paginator.component.html',
	styleUrls: ['year-paginator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearPaginatorComponent implements OnInit {
	public disableBtnNext: boolean;
	public disableBtnPrev: boolean;
	public viewYear: number;

	constructor(private _calendarDataService: CalendarDataService) {
		this.disableBtnNext = false;
		this.disableBtnPrev = true;
	}

	public ngOnInit(): void {
		this.viewYear = !!this._calendarDataService.getMonthsYear()
			? this._calendarDataService.getMonthsYear()
			: this._calendarDataService.getViewTime().getFullYear();

		this.checkBtnsDisable();
	}

	public nextYear(): void {
		++this.viewYear;

		this.checkBtnsDisable();

		this._calendarDataService.setMonthsYear(this.viewYear);
	}

	public prevYear(): void {
		--this.viewYear;

		this.checkBtnsDisable();

		this._calendarDataService.setMonthsYear(this.viewYear);
	}

	public openViewYears(): void {
		this._calendarDataService.setViewModes({
			dateMode: 'years',
			calendarMode: 'date',
		});
	}

	private checkBtnsDisable(): void {
		this.disableBtnNext = !!this._calendarDataService.getMaxDate()
			? this.viewYear >=
				this._calendarDataService.getMaxDate().getFullYear()
			: false;

		this.disableBtnPrev = !!this._calendarDataService.getMinDate()
			? this.viewYear <=
				this._calendarDataService.getMinDate().getFullYear()
			: false;
	}
}
