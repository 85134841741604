<app-calendar-header
	[viewDateTime]="viewDate.getTime()"
	[viewModeDate]="viewModeDate"
	[viewModeCalendar]="viewModeCalendar"
>
</app-calendar-header>

<ng-container *ngIf="viewModeCalendar === 'calendar'">
	<div *ngIf="viewModeCalendar === 'calendar'"
		 class="calendar"
	>
		<div class="week-days">
			<div *ngFor="let weekDay of weekDays; let i = index"
				 class="week-day day">
				{{ weekDay }}
			</div>
		</div>

		<div class="days">
			<div *ngFor="let prevDay of previousViewDays"
				 class="prev day"
				 [class.checkmark]="viewCheckmarksMap.get('prev' + prevDay)"
				 [class.disabled]="calendarDataService.isDateDisabled('prev' + prevDay)"
				 [class]="periodMap.get('prev' + prevDay)"
				 (click)="choiceDatePrevMonth(prevDay)"
			>
				<div class="day-item">
					{{ prevDay }}
				</div>
			</div>
			<div *ngFor="let day of viewDays"
				 class="day"
				 [class.checkmark]="viewCheckmarksMap.get('current' + day)"
				 [class.disabled]="calendarDataService.isDateDisabled('current' + day)"
				 [class]="periodMap.get('current' + day)"
				 (click)="choiceDate(day)"
			>
				<div class="day-item">
					{{ day }}
				</div>
			</div>
			<div *ngFor="let nextDay of nextViewDays"
				 class="next day"
				 [class.checkmark]="viewCheckmarksMap.get('next' + nextDay)"
				 [class.disabled]="calendarDataService.isDateDisabled('next' + nextDay)"
				 [class]="periodMap.get('next' + nextDay)"
				 (click)="choiceDateNextMonth(nextDay)"
			>
				<div class="day-item">
					{{ nextDay }}
				</div>
			</div>
		</div>
	</div>
</ng-container>

<app-select-year *ngIf="viewModeCalendar === 'date'"
				 [viewMode]="viewModeDate"
				 (changeDate)="setDate($event)"
				 (close)="changeMode('calendar', 'years')"
></app-select-year>

<div class="basement">
	<ng-content select="[basement]"></ng-content>
</div>
