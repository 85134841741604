<form [formGroup]="group">
	<input formControlName="hours"
		   (input)="changeValue()"
		   (focusout)="useMask('hours')"
		   (focusin)="setFocus(0)"
	/>

	<div class="dots"></div>

	<input formControlName="minutes"
		   (input)="changeValue()"
		   (focusout)="useMask('minutes')"
		   (focusin)="setFocus(1)"
	/>
</form>
