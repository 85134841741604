import moment from 'moment';
import { ICustomPeriodCalendar, TKeyOfIntervalPeriod } from '@shared/ui-components/n-inverval/models/interval.types';

export const CustomPeriodConfig: Record<
	TKeyOfIntervalPeriod,
	ICustomPeriodCalendar
> = {
	customPeriod: {
		title: 'Свой период',
		start: null,
		end: null,
	},
	lastHour: {
		title: 'Последний час',
		start: () => moment().add(-1, 'hour').startOf('minute').toDate(),
		end: () => moment().startOf('minute').toDate(),
	},
	today: {
		title: 'Сегодня',
		start: () => moment().startOf('day').toDate(),
		end: () => moment().startOf('minute').toDate(),
	},
	yesterday: {
		title: 'Вчера',
		start: () => moment().add(-1, 'day').startOf('day').toDate(),
		end: () => moment().add(-1, 'day').endOf('day').toDate(),
	},
	week: {
		title: 'Текущая неделя',
		start: () => moment().startOf('week').toDate(),
		end: () => moment().startOf('minute').toDate(),
	},
	lastWeek: {
		title: 'Прошлая неделя',
		start: () => moment().add(-7, 'day').startOf('week').toDate(),
		end: () => moment().add(-7, 'day').endOf('week').toDate(),
	},
	month: {
		title: 'Текущий месяц',
		start: () => moment().startOf('month').toDate(),
		end: () => moment().startOf('minute').toDate(),
	},
	lastMonth: {
		title: 'Прошлый месяц',
		start: () => moment().add(-1, 'month').startOf('month').toDate(),
		end: () => moment().add(-1, 'month').endOf('month').toDate(),
	},
};

export const CustomPeriodConfigWithMaxDate: Record<
	TKeyOfIntervalPeriod,
	ICustomPeriodCalendar
> = {
	customPeriod: {
		title: 'Свой период',
		start: null,
		end: null,
	},
	lastHour: {
		title: 'Последний час',
		start: () => moment().add(-1, 'hour').startOf('minute').toDate(),
		end: () => moment().startOf('minute').toDate(),
	},
	today: {
		title: 'Сегодня',
		start: () => moment().startOf('day').toDate(),
		end: () => moment().endOf('day').toDate(),
	},
	yesterday: {
		title: 'Вчера',
		start: () => moment().add(-1, 'day').startOf('day').toDate(),
		end: () => moment().add(-1, 'day').endOf('day').toDate(),
	},
	week: {
		title: 'Текущая неделя',
		start: () => moment().startOf('week').toDate(),
		end: () => moment().endOf('week').toDate(),
	},
	lastWeek: {
		title: 'Прошлая неделя',
		start: () => moment().add(-7, 'day').startOf('week').toDate(),
		end: () => moment().add(-7, 'day').endOf('week').toDate(),
	},
	month: {
		title: 'Текущий месяц',
		start: () => moment().startOf('month').toDate(),
		end: () => moment().endOf('month').toDate(),
	},
	lastMonth: {
		title: 'Прошлый месяц',
		start: () => moment().add(-1, 'month').startOf('month').toDate(),
		end: () => moment().add(-1, 'month').endOf('month').toDate(),
	},
};
