import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {HEIGHT_MATRIX_YEARS, WIDTH_MATRIX_YEARS,} from '@shared/ui-components/n-calendar/models/calendar.constants';
import {CalendarDataService,} from '@shared/ui-components/n-calendar/services/calendar-data.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IPeriodYears} from '@shared/ui-components/n-calendar/models/calendar.types';

@UntilDestroy()
@Component({
	selector: 'app-years-paginator',
	templateUrl: 'years-paginator.component.html',
	styleUrls: ['years-paginator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearsPaginatorComponent implements OnInit {
	public disableBtnNext: boolean;
	public disableBtnPrev: boolean;
	public yearsPeriod: string;
	public date: Date;

	constructor(private _calendarDateService: CalendarDataService) {
		this.disableBtnNext = false;
		this.disableBtnPrev = false;
	}

	public ngOnInit(): void {
		this.initListeners();
	}

	public initListeners(): void {
		this._calendarDateService
			.getViewTimeListener()
			.pipe(untilDestroyed(this))
			.subscribe(res => {
				this.date = new Date(res);
				this.recalculationYearsPeriod();
			});
	}

	public recalculationYearsPeriod(): void {
		const yearStart = this.getStartYear();
		const yearEnd = this.getLastYear();

		this.yearsPeriod = yearStart + ' - ' + yearEnd;
		this.checkBtnsDisable(yearStart, yearEnd);
	}

	public nextPeriod(): void {
		this.date.setFullYear(
			this.date.getFullYear() + WIDTH_MATRIX_YEARS * HEIGHT_MATRIX_YEARS
		);
		this.recalculationYearsPeriod();

		this._calendarDateService.setPeriodYears(this.getPeriodYears());
	}

	public prevPeriod(): void {
		this.date.setFullYear(
			this.date.getFullYear() - WIDTH_MATRIX_YEARS * HEIGHT_MATRIX_YEARS
		);
		this.recalculationYearsPeriod();

		this._calendarDateService.setPeriodYears(this.getPeriodYears());
	}

	private getStartYear(): number {
		const yearCurrentDate = this.date.getFullYear();

		return yearCurrentDate - WIDTH_MATRIX_YEARS;
	}

	private getLastYear(): number {
		const yearCurrentDate = this.date.getFullYear();

		return (
			yearCurrentDate +
			((HEIGHT_MATRIX_YEARS - 1) * WIDTH_MATRIX_YEARS - 1)
		);
	}

	private getPeriodYears(): IPeriodYears {
		return {
			from: this.getStartYear(),
			to: this.getLastYear(),
		};
	}

	private checkBtnsDisable(start: number, end: number): void {
		this.disableBtnPrev = !!this._calendarDateService.getMinDate()
			? this._calendarDateService.getMinDate().getFullYear() >= start
			: false;
		this.disableBtnNext = !!this._calendarDateService.getMaxDate()
			? this._calendarDateService.getMaxDate().getFullYear() <= end
			: false;
	}
}
