import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EmbeddedViewRef,
	EventEmitter,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {NAME_MONTH, NameMonth, templateType} from '@shared/ui-components/n-calendar/models/calendar.constants';
import { CalendarDataService } from '@shared/ui-components/n-calendar/services/calendar-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'app-months-paginator',
	templateUrl: 'months-paginator.component.html',
	styleUrls: ['months-paginator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthsPaginatorComponent implements OnInit {
	@ViewChild('templateContainer') templateContainer: TemplateRef<any>;
	@ViewChild('viewContainer', { read: ViewContainerRef })
	viewContainer: ViewContainerRef;

	@Output()
	openSelectMonth: EventEmitter<void> = new EventEmitter<void>();

	private _selectMonth: number;
	private _viewRef: EmbeddedViewRef<any> | null = null;

	public nameMonth: templateType<number, NameMonth>;

	public date: Date;
	public disabledNext: boolean;
	public disabledPrev: boolean;

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
		private _calendarDataService: CalendarDataService
	) {
		this._selectMonth = 0;
		this._viewRef = null;

		this.nameMonth = NAME_MONTH;

		this.disabledNext = false;
		this.disabledPrev = false;
	}

	public ngOnInit(): void {
		this.date = new Date(this._calendarDataService.getViewTime());
		this.date.setDate(1); // необходимо для правильной инкременции месяца
		this._selectMonth = this.date.getMonth();
		this.checkDisabledBtns();

		setTimeout(() => {
			this.createView();
			this.changeDetectorRef.markForCheck();
			this.initListeners();
		});
	}

	private initListeners(): void {
		this._calendarDataService
			.getViewTimeListener()
			.pipe(
				untilDestroyed(this),
				distinctUntilChanged(
					(one, two) => two.getMonth() === this._selectMonth
				)
			)
			.subscribe(res => {
				this.date = new Date(res);
				this.date.setDate(1); // необходимо для правильной инкременции месяца
				this._selectMonth = this.date.getMonth();

				this.createView();
				this.changeDetectorRef.markForCheck();
			});
	}

	private checkDisabledBtns(): void {
		const date = new Date(this._calendarDataService.getViewTime());

		if (!!this._calendarDataService.getMaxDate()) {
			this.disabledNext =
				date.getMonth() + date.getFullYear() * 100 >=
				this._calendarDataService.getMaxDate().getMonth() +
					this._calendarDataService.getMaxDate().getFullYear() * 100;
		}

		if (!!this._calendarDataService.getMinDate()) {
			this.disabledPrev =
				date.getMonth() + date.getFullYear() * 100 <=
				this._calendarDataService.getMinDate().getMonth() +
					this._calendarDataService.getMinDate().getFullYear() * 100;
		}
	}

	public next(): void {
		++this._selectMonth;
		this.date.setMonth(this._selectMonth);

		if (this._selectMonth === 12) {
			this._selectMonth = 0;
		}

		this.createView();
		this._calendarDataService.setViewTime(this.date);
		this.checkDisabledBtns();
	}

	public prev(): void {
		--this._selectMonth;
		this.date.setMonth(this._selectMonth);

		if (this._selectMonth === -1) {
			this._selectMonth = 11;
		}
		this.createView();
		this._calendarDataService.setViewTime(this.date);
		this.checkDisabledBtns();
	}

	public createView(): void {
		if (!!this._viewRef) {
			this._viewRef.destroy();
		}

		this._viewRef = this.templateContainer.createEmbeddedView({
			index: this._selectMonth,
		});

		this.viewContainer.insert(this._viewRef);
	}
}
